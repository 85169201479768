export default {
  labels: {
    id: 'Id',
    apply: 'Anwenden',
    cancel: 'Abbrechen',
    back: 'Zurück',
    forward: 'Weiter',
    reset: 'Zurücksetzen',
    submit: 'Absenden',
    save: 'Speichern',
    saveCopy: 'Als Kopie speichern',
    upload: 'Hochladen',
    download: 'Herunterladen',
    compare: 'Vergleichen',
    discard: 'Verwerfen',
    saveChanges: 'Änderungen speichern',
    export: 'Exportieren',
    create: 'Erstellen',
    delete: 'Löschen',
    duplicate: 'Duplizieren',
    confirm: 'Bestätigen',
    rotateLeft: 'Nach rechts drehen',
    rotateRight: 'Nach links drehen',
    open: 'Öffnen',
    preview: 'Vorschau',
    external_preview: 'Öffnen in neuer Seite',
    tab_preview: 'Öffnen in neuer Seite',
    select: 'Auswählen',
    edit: 'Bearbeiten',
    rename: 'Umbenennen',
    search: 'Suchen...',
    done: 'Fertig',
    title: 'Titel',
    name: 'Name',
    contact_name: 'Name/Bezeichnung',
    firstname: 'Vorname',
    lastname: 'Nachname',
    email: 'E-Mail',
    primary_email: 'Primäre E-Mail Adresse',
    secondary_email: 'Sekundäre E-Mail Adresse',
    alternate_email: 'Alternative E-Mail Adresse',
    primary_phone: 'Primäre Telefonnummer',
    secondary_phone: 'Sekundäre Telefonnummer',
    password: 'Passwort',
    password_confirmation: 'Passwort wdh.',
    otp: 'Sicherheitscode',
    phone: 'Telefon',
    phone2: 'Telefon 2',
    mobile: 'Mobil',
    mobile2: 'Mobil 2',
    changeAssignments: 'Zuweisung ändern',
    fax: 'Fax',
    iban: 'IBAN',
    bic: 'BIC',
    role: 'Rolle',
    location: 'Niederlassung',
    qualifications: 'Qualifikationen',
    general: 'Allgemein',
    salutation: 'Anrede',
    function: 'Funktion',
    street: 'Straße',
    city: 'Stadt',
    country: 'Land',
    description: 'Beschreibung',
    street_no: 'Hausnr.',
    postcode: 'PLZ',
    defaultItem: 'Bitte auswählen',
    defaultItemAll: 'Alle',
    defaultItemAllCountries: 'All countries',
    loadingItem: 'Lädt…',
    notes: 'Notizen',
    bankDetails: 'Bank details',
    communication: 'Kommunikation',
    personal: 'Personal',
    address: 'Addresse',
    version: 'Version',
    yes: 'Ja',
    no: 'Nein',
    on: 'An',
    off: 'Aus',
    date: 'Datum',
    time: 'Uhrzeit',
    close: 'Schließen',
    loggedInAs: 'Eingeloggt als',
    loginAs: 'Einloggen als {{name}}',
    actions: 'Aktionen',
    view: 'Ansehen',
    from: 'Von',
    to: 'Bis',
    new: 'Neu',
    add: 'Hinzufügen',
    remove: 'Entfernen',
    update: 'Ändern',
    latitude: 'Latitude',
    longitude: 'Longitude',
    designer: 'Designer',
    copy: 'Kopieren',
    copied: 'Kopiert',
    finishDate: 'End-Datum',
    color: 'Farbe',
    code: 'Code',
    classify: 'Klassifizierung',
    createdAtDate: 'Erstellt am {{date}}',
    todayRelativeDays_zero: 'Heute',
    todayRelativeDays_one: '{{count}} Tag',
    todayRelativeDays_other: '{{count}} Tage',
    todayRelativeMinutes: '{{count}} Minuten',
    duration: 'Dauer',
    viewOnly: 'Nur ansehen',
    closeTemporarily: 'Temporär schließen',
    lastLogin: 'Letzter Login',
    sendEmail: 'E-Mail senden',
    sendMailInternal: 'über Inspcto',
    sendMailExternal: 'über lokales E-Mail Programm',
    appointment: 'Ortstermin',
    newAppMessage: 'Neue Systemnachricht',
    disabled: 'Deaktiviert',
    state: 'Status',
    globalTaskSearch: 'Aufträge durchsuchen...',
    none: 'Keine',
    all: 'Alle',
    refresh: 'Aktualisieren',
    notifications: 'Benachrichtigungen',
    retry: 'Nochmal versuchen',
    accept: 'Annehmen',
    decline: 'Ablehen',
    clearSelection: 'Auswahl löschen',
    dragHandle: 'Griff zum Verschieben',
    maximize: 'Maximieren',
    minimize: 'Minimieren',
    more: 'Mehr',
    colorPicker: 'Farbwähler',
    jobTitle: 'Job-Titel',
  },
  formats: {
    date: 'dd.MM.yyyy',
    dateTime: 'dd.MM.yyyy HH:mm:ss',
    timeWithoutSeconds: 'H:mm',
    dateTimeWithoutSeconds: 'dd.MM.yyyy HH:mm',
    time: 'H:mm:ss',
    dayMonth: 'dd.MM.',
    monthYear: 'MMMM yyyy',
  },
  validators: {
    required: ':field is required!',
  },
  navigation: {
    sections: {
      clients: 'Stammdaten',
      policyHolders: 'Versicherungsnehmer',
      tasks: 'Aufträge',
      account: 'Benutzerkonto',
      platform: 'Plattform',
      settings: 'Einstellungen',
      templateSettings: 'Vorlagen',
      serviceCatalog: 'Kalkulation',
      extendedSettings: 'Erweitert',
      chat: 'Chat',
      debug: 'Debug',
    },
    items: {
      overview: 'Übersicht',
      dashboard: 'Dashboard',
      clients: 'Stammdaten',
      policyHolders: 'Versicherungsnehmer',
      myTasks: 'Aufgaben',
      tasks: 'Aufträge',
      calendar: 'Kalender',
      invoices: 'Rechnungen',
      payouts: 'Auszahlungen',
      serviceCatalogServices: 'Leistungen',
      serviceCatalogPostcodeFactors: 'Ortsfaktoren',
      tradesStatus: 'Gewerke Status',
      reportingTemplates: 'Berichtsfragen',
      statisticsTemplates: 'Statistikfragen',
      emailTemplates: 'E-Mails',
      interfaceMessageTemplates: 'GDV Nachrichten',
      reports: 'Berichte',
      subtasks: 'Aufgaben',
      users: 'Benutzer',
      newTask: 'Neuer Auftrag',
      pdfs: 'PDF-Formulare',
      articles: 'Artikel',
      map: 'Zuweisungsgebiete',
      platform: 'Plattform',
      profile: 'Profil',
      branding: 'App-Einstellungen',
      folderTemplates: 'Standard-Ordner',
      profileSettings: 'Profileinstellungen',
      logout: 'Logout',
      qualifications: 'Qualifikationen',
      classificationTags: 'Auftrag-Klassifikationen',
      fileClassificationTags: 'Datei-Klassifikationen',
      subformClassificationTags: 'Unterformular-Klassifikationen',
      activityLog: 'Aktivitäten',
      demoData: 'Demo-Daten',
      stampTemplates: 'Stempel',
      devices: 'Geräte',
      addressBook: 'Adressbuch',
    },
  },
  client_types: {
    policy_holder: 'Policy Holder',
  },
  client_customer_state: {
    active: 'Aktiv',
    offer: 'Angebot',
    cancellation: 'Storno',
  },
  components: {
    templateTagInput: {
      addPlaceholder: 'Platzhalter hinzufügen',
      selectTagDialog: {
        title: 'Platzhalter wählen',
        description: 'Wähle einen Platzhalter aus der Liste aus.',
      },
    },
    remainingCharacterIndicator: {
      info: '{{remaining}} von {{max}} Zeichen verbleibend',
    },
    maskedTextInput: {
      maskValidationMessage: 'Eingabe entspricht nicht dem Format {{mask}} - siehe Info',
      info: {
        title: 'Eingabe von Masken',
        mask_0: '<bold>0</bold> - Erfordert eine Ziffer (0-9).',
        mask_9: '<bold>9</bold> - Erfordert eine Ziffer (0-9) oder ein Leerzeichen.',
        'mask_#':
          '<bold>#</bold> - Erfordert eine Ziffer (0-9), Leerzeichen, Plus- (+) oder Minuszeichen (-).',
        mask_L: '<bold>L</bold> - erfordert einen Buchstaben (a-Z).',
        'mask_?': '<bold>?</bold> - erfordert einen Buchstaben (a-Z) oder ein Leerzeichen.',
        mask_A: '<bold>A</bold> - Erfordert ein alphanumerisches Zeichen (0-9, a-Z).',
        mask_a:
          '<bold>a</bold> - Erfordert ein alphanumerisches Zeichen (0-9, a-Z) oder ein Leerzeichen.',
        'mask_&': '<bold>&</bold> - Erfordert ein Zeichen (außer Leerzeichen).',
        mask_C: '<bold>C</bold> - Erfordert ein Zeichen oder ein Leerzeichen.',
        escape:
          'Sie können auch spezifische Zeichen in ihrer Maske vorgeben. Falls ein Zeichen in der Liste oben steht, können Sie dieses mit (<bold>\\</bold>) überspringen. Beispiel: Für folgendes Format: "AX-123-456" sieht die Maske so aus: <bold>\\</bold>AX-000-000"',
      },
    },
    phoneInput: {
      invalid: 'Bitte geben Sie eine valide Telefonnummer ein.',
      hint: 'Eingabe im internationalen Format mit führendem +',
    },
    emailInput: {
      invalid: 'Bitte geben Sie eine valide Email-Adresse ein.',
    },
    emailInputWithTag: {
      invalid: 'Bitte geben Sie eine valide Email-Adresse oder einen validen Platzhalter ein.',
    },
    calendarToolbar: {
      today: 'Heute',
      prev: 'Zurückspringen',
      next: 'Nach vorne springen',
      timeline: 'Zeitleiste',
      day: 'Tag',
      week: 'Woche',
      month: 'Monat',
      agenda: 'Terminplaner',
      createEvent: 'Termin erstellen',
    },
    calendar: {
      appointment: 'Termin',
      note: 'Hier können Sie einen Neuen Termin eintragen.',
      start: 'Start',
      end: 'Ende',
      title: 'Titel',
      description: 'Beschreibung',
      isAllDay: 'Ganztags',
    },
    layout: {
      pageHeader: {
        unread: 'Ungelesen',
        createdAt: 'vor {{time}}',
        markAsRead: {
          error: 'Benachrichtigung kann nicht als gelesen marktiert werden',
          success: 'Benachrichtigung als gelesen markiert',
        },
        markAllAsRead: {
          title: 'Alle als gelesen markieren',
          error: 'Benachrichtigungen können nicht als gelesen markiert werden',
          success: 'Alle Benachrichtigungen als gelesen markiert',
        },
      },
      historyPopup: {
        label: 'Zuletzt besuchte Seiten öffnen',
        lastVisitedPages: 'Zuletzt besuchte Seiten',
        deleteHistory: 'Verlauf löschen',
      },
    },
    insuranceAssignmentListboxes: {
      all: 'Alle Versicherungen',
      active: 'Aktiv für diese Versicherungen',
    },
    footer: {
      termsOfService: 'Nutzungsbedingungen',
      privacy: 'Datenschutz',
      contact: 'Kontakt',
      imprint: 'Impressum',
    },
    strength_bar: {
      security: 'Sicherheit',
      strength_1: 'sehr schwach',
      strength_2: 'schwach',
      strength_3: 'mittelmäßig',
      strength_4: 'gut',
      strength_5: 'sehr gut',
    },
    filterDialog: {
      title: 'Erweiterte Filter',
      note: 'Hier können Sie komplexere Filter für Ihre Aufträge konfigurieren.',
      tabs: {
        common: 'Standard',
        advanced: 'Experten-Modus',
      },
    },
    filterBar: {
      additional_filters: 'Weitere Filter({{num_filters}})',
      showFilters: 'Filter anzeigen',
    },
    filter: {
      popoverTitle: 'Zeige nur',
      selectAll: 'Zeige alle',
      deselectAll: 'Alle abwählen',
      openFilter: 'Filter öffnen',
      closeFilter: 'Filter schließen',
    },
    maps: {
      mapSettings: {
        label: 'Karteneinstellungen anzeigen',
        titleMapStyle: 'Kartendarstellung',
        monochrome: 'Monochrom',
        satellite: 'Satellit',
        streets: 'Bunt',
      },
      labels: {
        zoomTaskBounds: 'Zoom anpassen, um alle Aufträge anzuzeigen',
      },
    },
    customUpload: {
      uploadFile: 'Datei hochladen',
      uploadFailed: 'Upload fehlgeschlagen',
      uploading: 'Lädt hoch …',
      uploadSuccessful: 'Upload erfolgreich',
      validationErrors: {
        invalidFileExtension: 'Ungültige Dateiendung',
        invalidMaxFileSize: 'Datei zu groß. Maximal {{maxFileSize}} erlaubt.',
      },
    },
    inputs: {
      switch: {
        active: 'Aktiv',
        inactive: 'Inaktiv',
      },
    },
    category: {
      category: 'Kategorie',
      index: 'Index',
      addNewCategory: 'Neue Kategorie hinzufügen',
      validation: 'Bitte fügen Sie mindestens eine Kategorie hinzu',
    },
    changeUser: {
      popover: {
        title: 'Benutzer wechseln',
        description: 'Wählen Sie einen Benutzer aus, zu dem Sie wechseln möchten.',
        placeholder: 'Nach Benutzer suchen...',
        empty: 'Keine Bentzer gefunden.',
      },
      confirm: {
        title: 'Benutzer wechseln',
        description:
          'Möchten sie wirklich zu {{name}} wechseln? Wenn Sie auf “Bestätigen” klicken, werden Sie in allen Tabs als {{name}} angemeldet. Dadurch können ungespeicherte Daten verloren gehen.',
      },
      overlay: {
        title: 'Benutzerwechsel',
        description: 'Die Seite wird mit dem neuen Benutzer geladen.',
      },
      button: {
        backToPreviousUser: 'Zurück',
        switchUser: 'Benutzer wechseln',
      },
      quickSelect: {
        title: 'Schnellauswahl',
      },
    },
    downloads: {
      title: 'Downloads',
      filesInGeneration_one: '{{count}} Datei wird vorbereitet.',
      filesInGeneration_other: '{{count}} Dateien werden vorbereitet.',
    },
    uploadDropzone: {
      title: 'Dateiupload: {{uploaded}}/{{total}}',
      errors: {
        fileTooLarge: 'Datei ist zu groß. Maximal {{maxFileSize}} erlaubt.',
        fileInvalidType: 'Ungültiges Dateiformat. Erlaubt sind: {{allowedFileTypes}}.',
      },
    },
  },
  dialogs: {
    formNavigationBlocker: {
      title: 'Seite verlassen?',
      note: 'Möchten Sie die Änderungen speichern oder verwerfen?',
    },
    discard: {
      title: 'Nicht gespeicherte Änderungen',
      description: 'Möchten Sie die Änderungen speichern oder verwerfen?',
    },
    warning: {
      title: 'Warnung',
      description: 'Möchten Sie mit der Aktion fortfahren?',
    },
    export: {
      labels: {
        xlsx: 'Daten als XLSX exportieren',
      },
      success: 'Erfolgreich exportiert.',
      error: 'Fehler beim Export.',
    },
  },
  ipAllowlistGuard: {
    error: '403 Fehler',
    title: 'Sie haben keinen Zugriff auf diese Seite.',
    note: 'Sie können mit ihrer bestehenden Verbindung nicht auf diese Seite zugreifen. Bitte kontaktieren Sie Ihren Administrator, wenn Sie der Meinung sind, dass dies ein Fehler ist. Nach Freischaltung der Verbindung, klicken Sie bitte "Aktualisieren".',
  },
  pages: {
    not_found: {
      error: '404 Fehler',
      title: 'Seite nicht gefunden',
      note: 'Die von Ihnen angeforderte Seite wurde nicht gefunden.',
      back_to_home: 'Zur Startseite',
      back: 'Zurück',
      refresh: 'Aktualisieren',
    },
    loading: {
      title: 'Inhalte werden geladen ...',
      note: 'Anwendungsdaten werden geladen.\nBitte habe Sie etwas Geduld.',
    },
  },
  errors: {
    generic: 'Es ist etwas schiefgelaufen',
    transcribe: 'Fehler beim Transkribieren',
  },
  transcribing: {
    message: 'Audioaufnahme wurde gestartet...',
  },
  pdf: {
    stamps: {
      approved: {
        title: 'Genehmigt',
        subtitle: 'von {{name}}',
      },
    },
    documentLoading: 'Dokument wird geladen…',
    documentError: 'Fehler beim Laden des Dokuments',
  },
  logoutTimer: {
    message: 'Sie werden in {{time}} Sekunden automatisch ausgeloggt.',
  },
  notificationChannels: {
    internal: 'Intern',
    email: 'E-Mail',
    emailTemplate: {
      label: 'E-Mail Vorlage',
      defaultItem: 'Standard',
    },
    sms: 'SMS',
    push: 'Push-Nachricht',
  },
  notificationGroups: {
    clients: {
      title: 'Benutzer-Benachrichtigungen',
      description:
        'Auswählen, um festzulegen, wie hinterlegte Benutzer bei Aktionen informiert werden sollen.',
    },
    tasks: {
      title: 'Auftrags-Benachrichtigungen',
      description:
        'Auswählen, um festzulegen, wie hinterlegte Benutzer bei Aktionen informiert werden sollen.',
    },
  },
};
