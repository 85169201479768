import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskDetailFragmentDoc } from './taskDetailFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTaskMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.UpdateTaskInput;
}>;

export type UpdateTaskMutation = {
  __typename?: 'Mutation';
  updateTask: {
    __typename?: 'Task';
    id: string;
    info_file_id?: number | null;
    assigned_date?: string | null;
    follow_up_date?: string | null;
    reminder_date?: string | null;
    damage_date?: string | null;
    description?: string | null;
    damage_note?: string | null;
    identifier?: string | null;
    external_number?: string | null;
    parallel_number?: string | null;
    policy_number?: string | null;
    permissions: Array<Types.Permission>;
    reserve?: number | null;
    state_id: Types.TaskStateEnum;
    urgent: boolean;
    drive_log_distance?: number | null;
    drive_log_note?: string | null;
    initial_mileage?: number | null;
    final_mileage?: number | null;
    magic_plan_id?: string | null;
    info_file?: { __typename?: 'FileItem'; url: string } | null;
    article?: { __typename?: 'Article'; id: string; article_number: string } | null;
    appointment?: { __typename?: 'TaskEvent'; id: string; date_from: string } | null;
    client?: {
      __typename?: 'Client';
      id: string;
      name: string;
      email?: string | null;
      alternate_email?: string | null;
      phone?: string | null;
      job_title?: string | null;
      notes?: string | null;
      client_type?: {
        __typename?: 'ClientType';
        id: string;
        title: string;
        color: string;
        type_id: Types.ClientTypeId;
      } | null;
      user?: { __typename?: 'User'; id: string } | null;
      primary_location?: {
        __typename?: 'ClientLocation';
        longitude?: number | null;
        latitude?: number | null;
        street?: string | null;
        street_no?: string | null;
        postcode?: string | null;
        city?: string | null;
      } | null;
      client_additional_policy_holder?: {
        __typename?: 'ClientAdditionalPolicyHolder';
        insurance_policy_number?: string | null;
        customer_number?: string | null;
        customer_state_date?: string | null;
        customer_state?: {
          __typename?: 'ClientCustomerState';
          id: string;
          title: string;
          color: string;
        } | null;
      } | null;
      qualifications: {
        __typename?: 'ClientQualificationList';
        data: Array<{ __typename?: 'Qualification'; id: string; title: string; color: string }>;
      };
      assigned_email_templates?: Array<{
        __typename?: 'EmailTemplate';
        id: string;
        title: string;
        description?: string | null;
      }> | null;
      direct_message_email_templates?: Array<{
        __typename?: 'EmailTemplate';
        id: string;
        title: string;
        description?: string | null;
      }> | null;
      direct_message_interface_message_templates?: Array<{
        __typename?: 'InterfaceMessageTemplate';
        id: string;
        title: string;
        description?: string | null;
      }> | null;
      assigned_templates: Array<
        | { __typename: 'EmailTemplate'; id: string; title: string; description?: string | null }
        | {
            __typename: 'InterfaceMessageTemplate';
            id: string;
            title: string;
            description?: string | null;
          }
      >;
    } | null;
    client_contact?: {
      __typename?: 'Contact';
      id: string;
      full_name?: string | null;
      phone?: string | null;
      email?: string | null;
      note?: string | null;
    } | null;
    client_location?: {
      __typename?: 'ClientLocation';
      id: string;
      street?: string | null;
      street_no?: string | null;
      postcode?: string | null;
      city?: string | null;
    } | null;
    contacts: Array<{
      __typename?: 'Contact';
      id: string;
      role_id?: string | null;
      salutation_id?: string | null;
      function_id?: string | null;
      city?: string | null;
      country_id?: string | null;
      email?: string | null;
      email2?: string | null;
      fax_number?: string | null;
      first_name?: string | null;
      name?: string | null;
      mobile?: string | null;
      phone?: string | null;
      phone2?: string | null;
      postcode?: string | null;
      street?: string | null;
      street_no?: string | null;
      note?: string | null;
      country?: { __typename?: 'Country'; id: string; title: string } | null;
      salutation?: { __typename?: 'Salutation'; id: string; title: string } | null;
      function?: { __typename?: 'ContactFunction'; id: string; title: string } | null;
    }>;
    customer?: {
      __typename?: 'Contact';
      id: string;
      full_name?: string | null;
      phone?: string | null;
      phone2?: string | null;
      mobile?: string | null;
      mobile2?: string | null;
      email?: string | null;
      email2?: string | null;
    } | null;
    info_counts: {
      __typename?: 'TaskInfoCountList';
      data: Array<{
        __typename?: 'TaskInfoCount';
        type_id: Types.TaskInfoCountType;
        count: number;
      }>;
    };
    invoices?: Array<{
      __typename?: 'TaskInvoice';
      id: string;
      task_id: string;
      external_invoice_id?: string | null;
      external_invoice_number?: string | null;
      external_invoice_url?: string | null;
      external_id?: number | null;
      date: string;
      state_id: Types.TaskInvoiceStateId;
      note?: string | null;
      reminder?: boolean | null;
      external_number: string;
      identifier: string;
      address: string;
      sum_gross: number;
      sum_net: number;
      sum_tax: number;
      url_preview: string;
      task: { __typename?: 'Task'; identifier?: string | null };
      state: { __typename?: 'TaskInvoiceState'; id: string; title: string; color: string };
      items: Array<{
        __typename?: 'TaskInvoiceItem';
        id: string;
        position: string;
        title: string;
        unit?: string | null;
        unit_price: number;
        amount: number;
        sum_net: number;
        sum_gross: number;
        tax: number;
      }>;
      payouts?: Array<{
        __typename?: 'TaskInvoicePayout';
        id: string;
        title: string;
        sum_net: number;
        state: {
          __typename?: 'TaskInvoicePayoutState';
          id: string;
          title: string;
          color: string;
          state_id: Types.TaskInvoicePayoutStateId;
        };
      }> | null;
      file?: { __typename?: 'FileItem'; id: string; file_name: string; url: string } | null;
    }> | null;
    locations: Array<{
      __typename?: 'TaskLocation';
      id: string;
      task_location_type_id?: string | null;
      street?: string | null;
      street_no?: string | null;
      postcode?: string | null;
      city?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      country: { __typename?: 'Country'; id: string; title: string };
    }>;
    owner?: {
      __typename?: 'Client';
      id: string;
      name: string;
      email?: string | null;
      alternate_email?: string | null;
      phone?: string | null;
      job_title?: string | null;
      notes?: string | null;
      client_type?: {
        __typename?: 'ClientType';
        id: string;
        title: string;
        color: string;
        type_id: Types.ClientTypeId;
      } | null;
      user?: { __typename?: 'User'; id: string } | null;
      primary_location?: {
        __typename?: 'ClientLocation';
        longitude?: number | null;
        latitude?: number | null;
        street?: string | null;
        street_no?: string | null;
        postcode?: string | null;
        city?: string | null;
      } | null;
      client_additional_policy_holder?: {
        __typename?: 'ClientAdditionalPolicyHolder';
        insurance_policy_number?: string | null;
        customer_number?: string | null;
        customer_state_date?: string | null;
        customer_state?: {
          __typename?: 'ClientCustomerState';
          id: string;
          title: string;
          color: string;
        } | null;
      } | null;
      qualifications: {
        __typename?: 'ClientQualificationList';
        data: Array<{ __typename?: 'Qualification'; id: string; title: string; color: string }>;
      };
      assigned_email_templates?: Array<{
        __typename?: 'EmailTemplate';
        id: string;
        title: string;
        description?: string | null;
      }> | null;
      direct_message_email_templates?: Array<{
        __typename?: 'EmailTemplate';
        id: string;
        title: string;
        description?: string | null;
      }> | null;
      direct_message_interface_message_templates?: Array<{
        __typename?: 'InterfaceMessageTemplate';
        id: string;
        title: string;
        description?: string | null;
      }> | null;
      assigned_templates: Array<
        | { __typename: 'EmailTemplate'; id: string; title: string; description?: string | null }
        | {
            __typename: 'InterfaceMessageTemplate';
            id: string;
            title: string;
            description?: string | null;
          }
      >;
    } | null;
    customer_portal_events?: Array<{
      __typename?: 'CustomerPortalEvent';
      type_id: Types.CustomerPortalEventTypeId;
      allow_customer_upload: boolean;
      allow_customer_form: boolean;
      has_customer_form: boolean;
      contact_person_email: string;
      contact_person_name: string;
      contact_person_phone: string;
      customer_email?: string | null;
      customer_lat?: number | null;
      customer_lon?: number | null;
      customer_name: string;
      customer_phone?: string | null;
      from?: string | null;
      has_room: boolean;
      has_user_position?: boolean | null;
      hash: string;
      id: string;
      identifier: string;
      notes?: string | null;
      room_identifier?: string | null;
      send_email: boolean;
      send_email_date?: string | null;
      send_sms: boolean;
      send_sms_date?: string | null;
      to?: string | null;
      url?: string | null;
      has_video: boolean;
      task_id: string;
      type: {
        __typename?: 'CustomerPortalEventType';
        id: string;
        title: string;
        has_video: boolean;
        need_appointment: boolean;
      };
      files: Array<{
        __typename?: 'CustomerPortalEventFile';
        id: string;
        file?: {
          __typename?: 'FileItem';
          id: string;
          name: string;
          file_name: string;
          original_file_name: string;
          url: string;
          url_download: string;
          url_thumb?: string | null;
          size: number;
          updated_at?: string | null;
          created_at?: string | null;
          is_extracting_pages: boolean;
          permissions: Array<Types.Permission>;
          url_pages?: Array<{
            __typename?: 'FileItemPage';
            page_index: number;
            url: string;
          }> | null;
          custom_properties: {
            __typename?: 'FileItemCustomProperties';
            description?: string | null;
            is_cover?: boolean | null;
            visible?: boolean | null;
            number_of_pages?: number | null;
            export_pages?: Array<number> | null;
            extracted_pages?: Array<number> | null;
          };
        } | null;
      }>;
      documents_for_customer: Array<{
        __typename?: 'CustomerPortalEventDocument';
        id: string;
        original_file?: {
          __typename?: 'FileItem';
          id: string;
          name: string;
          file_name: string;
          original_file_name: string;
          url: string;
          url_download: string;
          url_thumb?: string | null;
          size: number;
          updated_at?: string | null;
          created_at?: string | null;
          is_extracting_pages: boolean;
          permissions: Array<Types.Permission>;
          url_pages?: Array<{
            __typename?: 'FileItemPage';
            page_index: number;
            url: string;
          }> | null;
          custom_properties: {
            __typename?: 'FileItemCustomProperties';
            description?: string | null;
            is_cover?: boolean | null;
            visible?: boolean | null;
            number_of_pages?: number | null;
            export_pages?: Array<number> | null;
            extracted_pages?: Array<number> | null;
          };
        } | null;
        modified_file?: {
          __typename?: 'FileItem';
          id: string;
          name: string;
          file_name: string;
          original_file_name: string;
          url: string;
          url_download: string;
          url_thumb?: string | null;
          size: number;
          updated_at?: string | null;
          created_at?: string | null;
          is_extracting_pages: boolean;
          permissions: Array<Types.Permission>;
          url_pages?: Array<{
            __typename?: 'FileItemPage';
            page_index: number;
            url: string;
          }> | null;
          custom_properties: {
            __typename?: 'FileItemCustomProperties';
            description?: string | null;
            is_cover?: boolean | null;
            visible?: boolean | null;
            number_of_pages?: number | null;
            export_pages?: Array<number> | null;
            extracted_pages?: Array<number> | null;
          };
        } | null;
      }>;
    }> | null;
    state: { __typename?: 'TaskState'; id: string; color: string; title: string };
    leader?: {
      __typename?: 'Client';
      id: string;
      name: string;
      email?: string | null;
      alternate_email?: string | null;
      phone?: string | null;
      job_title?: string | null;
      notes?: string | null;
      client_type?: {
        __typename?: 'ClientType';
        id: string;
        title: string;
        color: string;
        type_id: Types.ClientTypeId;
      } | null;
      user?: { __typename?: 'User'; id: string } | null;
      primary_location?: {
        __typename?: 'ClientLocation';
        longitude?: number | null;
        latitude?: number | null;
        street?: string | null;
        street_no?: string | null;
        postcode?: string | null;
        city?: string | null;
      } | null;
      client_additional_policy_holder?: {
        __typename?: 'ClientAdditionalPolicyHolder';
        insurance_policy_number?: string | null;
        customer_number?: string | null;
        customer_state_date?: string | null;
        customer_state?: {
          __typename?: 'ClientCustomerState';
          id: string;
          title: string;
          color: string;
        } | null;
      } | null;
      qualifications: {
        __typename?: 'ClientQualificationList';
        data: Array<{ __typename?: 'Qualification'; id: string; title: string; color: string }>;
      };
      assigned_email_templates?: Array<{
        __typename?: 'EmailTemplate';
        id: string;
        title: string;
        description?: string | null;
      }> | null;
      direct_message_email_templates?: Array<{
        __typename?: 'EmailTemplate';
        id: string;
        title: string;
        description?: string | null;
      }> | null;
      direct_message_interface_message_templates?: Array<{
        __typename?: 'InterfaceMessageTemplate';
        id: string;
        title: string;
        description?: string | null;
      }> | null;
      assigned_templates: Array<
        | { __typename: 'EmailTemplate'; id: string; title: string; description?: string | null }
        | {
            __typename: 'InterfaceMessageTemplate';
            id: string;
            title: string;
            description?: string | null;
          }
      >;
    } | null;
    primary_form?: {
      __typename?: 'TaskForm';
      id: string;
      template: {
        __typename?: 'TaskTemplate';
        id: string;
        color: string;
        title: string;
        enable_word_convert?: boolean | null;
        type_id: Types.TaskTemplateTypeId;
      };
    } | null;
    primary_location?: {
      __typename?: 'TaskLocation';
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    statistics_form: {
      __typename?: 'TaskForm';
      id: string;
      template: { __typename?: 'TaskTemplate'; id: string };
    };
    forms: Array<{
      __typename?: 'TaskForm';
      id: string;
      updated_at: string;
      title: string;
      sort_index: number;
      type: { __typename?: 'TaskFormType'; id: string; type_id: Types.TaskFormTypeId };
      template: {
        __typename?: 'TaskTemplate';
        id: string;
        title: string;
        description?: string | null;
        type_id: Types.TaskTemplateTypeId;
      };
      tags?: Array<{
        __typename?: 'Tag';
        id: string;
        title: string;
        color: string;
        type_id: string;
        type: { __typename?: 'TagType'; id: string; title: string; color: string };
      }> | null;
    }>;
    visit_type?: {
      __typename?: 'TaskVisitType';
      id: string;
      title: string;
      need_location: boolean;
    } | null;
    allowed_clients_for_sub_task_assignment: {
      __typename?: 'TaskSubTaskAllowedClientsForAssignment';
      clients: Array<{
        __typename?: 'Client';
        id: string;
        name: string;
        job_title?: string | null;
        client_type?: {
          __typename?: 'ClientType';
          color: string;
          title: string;
          id: string;
        } | null;
      }>;
    };
    classification_tags: {
      __typename?: 'TaskClassificationTagList';
      id: string;
      data: Array<{ __typename?: 'ClassificationTag'; id: string; title: string; color: string }>;
    };
    qualifications: {
      __typename?: 'TaskQualificationList';
      data: Array<{ __typename?: 'Qualification'; title: string; id: string; color: string }>;
    };
    notifications?: Array<{
      __typename?: 'TaskNotification';
      description: string;
      title: string;
      type: Types.PageNotificationType;
    }> | null;
    sub_owners: Array<{
      __typename?: 'TaskMember';
      id: string;
      type: Types.TaskMemberType;
      task_id: string;
      client_id: string;
      client?: {
        __typename?: 'Client';
        id: string;
        name: string;
        phone?: string | null;
        email?: string | null;
        notes?: string | null;
        job_title?: string | null;
        client_type?: {
          __typename?: 'ClientType';
          id: string;
          title: string;
          color: string;
        } | null;
      } | null;
    }>;
    group?: { __typename?: 'TaskGroup'; id: string; title: string; color: string } | null;
  };
};

export const UpdateTaskDocument = gql`
  mutation UpdateTask($id: ID!, $input: UpdateTaskInput!) {
    updateTask(id: $id, input: $input) {
      ...TaskDetail
    }
  }
  ${TaskDetailFragmentDoc}
`;

export function useUpdateTaskMutation() {
  return Urql.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument);
}
