import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { TaskFileFieldsFragmentDoc } from './taskFoldersAndFiles.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type MoveTaskFilesToFolderMutationVariables = Types.Exact<{
  folder_id: Types.Scalars['ID'];
  file_ids: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;

export type MoveTaskFilesToFolderMutation = {
  __typename?: 'Mutation';
  moveTaskFilesToFolder: {
    __typename?: 'TaskFilesPaginator';
    data: Array<{
      __typename?: 'FileItem';
      id: string;
      name: string;
      original_file_name: string;
      size: number;
      mime_type?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      url: string;
      permissions: Array<Types.Permission>;
      client?: {
        __typename?: 'Client';
        name: string;
        job_title?: string | null;
        client_type?: { __typename?: 'ClientType'; title: string } | null;
      } | null;
      custom_properties: {
        __typename?: 'FileItemCustomProperties';
        folder_id?: string | null;
        invoice?: {
          __typename?: 'FileItemInvoiceMetaData';
          identifier: string;
          sum_gross: number;
          sum_net: number;
          sum_tax: number;
        } | null;
      };
      classification_tags: {
        __typename?: 'FileClassificationTagList';
        data: Array<{
          __typename?: 'FileClassificationTag';
          id: string;
          title: string;
          color: string;
          alias: string;
          type_id: Types.FileClassificationTagTypeId;
        }>;
      };
    }>;
  };
};

export const MoveTaskFilesToFolderDocument = gql`
  mutation MoveTaskFilesToFolder($folder_id: ID!, $file_ids: [ID!]!) {
    moveTaskFilesToFolder(folder_id: $folder_id, file_ids: $file_ids) {
      data {
        ...TaskFileFields
      }
    }
  }
  ${TaskFileFieldsFragmentDoc}
`;

export function useMoveTaskFilesToFolderMutation() {
  return Urql.useMutation<MoveTaskFilesToFolderMutation, MoveTaskFilesToFolderMutationVariables>(
    MoveTaskFilesToFolderDocument,
  );
}
