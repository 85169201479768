import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import { FileClassificationTagFragmentDoc } from '../../../classification-tags/graphql/generated/fileClassificationTagFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type TaskFolderFieldsFragment = {
  __typename?: 'TaskFolder';
  id: string;
  name: string;
  task_id: string;
  parent_id?: string | null;
  folder_type: Types.TaskFolderType;
  allowed_client_type_ids: Array<Types.ClientTypeId>;
};

export type TaskFileFieldsFragment = {
  __typename?: 'FileItem';
  id: string;
  name: string;
  original_file_name: string;
  size: number;
  mime_type?: string | null;
  created_at?: string | null;
  updated_at?: string | null;
  url: string;
  permissions: Array<Types.Permission>;
  client?: {
    __typename?: 'Client';
    name: string;
    job_title?: string | null;
    client_type?: { __typename?: 'ClientType'; title: string } | null;
  } | null;
  custom_properties: {
    __typename?: 'FileItemCustomProperties';
    folder_id?: string | null;
    invoice?: {
      __typename?: 'FileItemInvoiceMetaData';
      identifier: string;
      sum_gross: number;
      sum_net: number;
      sum_tax: number;
    } | null;
  };
  classification_tags: {
    __typename?: 'FileClassificationTagList';
    data: Array<{
      __typename?: 'FileClassificationTag';
      id: string;
      title: string;
      color: string;
      alias: string;
      type_id: Types.FileClassificationTagTypeId;
    }>;
  };
};

export type TaskFoldersAndFilesQueryVariables = Types.Exact<{
  task_id: Types.Scalars['ID'];
}>;

export type TaskFoldersAndFilesQuery = {
  __typename?: 'Query';
  taskFiles: {
    __typename?: 'TaskFilesPaginator';
    data: Array<{
      __typename?: 'FileItem';
      id: string;
      name: string;
      original_file_name: string;
      size: number;
      mime_type?: string | null;
      created_at?: string | null;
      updated_at?: string | null;
      url: string;
      permissions: Array<Types.Permission>;
      client?: {
        __typename?: 'Client';
        name: string;
        job_title?: string | null;
        client_type?: { __typename?: 'ClientType'; title: string } | null;
      } | null;
      custom_properties: {
        __typename?: 'FileItemCustomProperties';
        folder_id?: string | null;
        invoice?: {
          __typename?: 'FileItemInvoiceMetaData';
          identifier: string;
          sum_gross: number;
          sum_net: number;
          sum_tax: number;
        } | null;
      };
      classification_tags: {
        __typename?: 'FileClassificationTagList';
        data: Array<{
          __typename?: 'FileClassificationTag';
          id: string;
          title: string;
          color: string;
          alias: string;
          type_id: Types.FileClassificationTagTypeId;
        }>;
      };
    }>;
  };
  taskFolders: Array<{
    __typename?: 'TaskFolder';
    id: string;
    name: string;
    task_id: string;
    parent_id?: string | null;
    folder_type: Types.TaskFolderType;
    allowed_client_type_ids: Array<Types.ClientTypeId>;
  }>;
};

export const TaskFolderFieldsFragmentDoc = gql`
  fragment TaskFolderFields on TaskFolder {
    id
    name
    task_id
    parent_id
    folder_type
    allowed_client_type_ids
  }
`;
export const TaskFileFieldsFragmentDoc = gql`
  fragment TaskFileFields on FileItem {
    id
    name
    original_file_name
    size
    mime_type
    created_at
    updated_at
    url
    client {
      name
      job_title
      client_type {
        title
      }
    }
    custom_properties {
      folder_id
      invoice {
        identifier
        sum_gross
        sum_net
        sum_tax
      }
    }
    classification_tags {
      data {
        ...FileClassificationTag
      }
    }
    permissions
  }
  ${FileClassificationTagFragmentDoc}
`;
export const TaskFoldersAndFilesDocument = gql`
  query TaskFoldersAndFiles($task_id: ID!) {
    taskFiles(task_id: $task_id) {
      data {
        ...TaskFileFields
      }
    }
    taskFolders(task_id: $task_id) {
      ...TaskFolderFields
    }
  }
  ${TaskFileFieldsFragmentDoc}
  ${TaskFolderFieldsFragmentDoc}
`;

export function useTaskFoldersAndFilesQuery(
  options: Omit<Urql.UseQueryArgs<TaskFoldersAndFilesQueryVariables>, 'query'>,
) {
  return Urql.useQuery<TaskFoldersAndFilesQuery, TaskFoldersAndFilesQueryVariables>({
    query: TaskFoldersAndFilesDocument,
    ...options,
  });
}
